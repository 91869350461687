import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { object } from 'prop-types'
import { Icon, Button } from 'semantic-ui-react'
import dayjs from 'dayjs'

import favicon from '../../static/favicon.png'
import 'prismjs/themes/prism-tomorrow.css'
import 'semantic-ui-css/semantic.min.css'
import '../../assets/main.scss'

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const shouldRenderHeader =
      location.pathname === '/' ||
      location.pathname === '/thoughts/' ||
      location.pathname === '/thoughts'

    return (
      <Fragment>
        <Helmet>
          <script
            src="https://kit.fontawesome.com/cc9e3dd3aa.js"
            crossOrigin="anonymous"
          ></script>
          <link rel="shortcut icon" type="image/png" href={favicon} />
        </Helmet>
        {shouldRenderHeader && (
          <header
            className="ep-padding-12 ep-mb-section ep-space-around center-block"
            style={{ marginTop: 20 }}
          >
            <Link to="/tags">
              <Button basic icon labelPosition="left">
                <Button.Content visible>My posts by tag</Button.Content>
                <Icon name="tag" />
              </Button>
            </Link>
          </header>
        )}
        <main className="ep__content">{children}</main>
        <footer>
          Code snippets licensed under{' '}
          <a
            href="https://oss.ninja/mit"
            target="_blank"
            rel="noopener noreferrer"
          >
            MIT
          </a>
          , unless otherwise noted.
          <br /> Content © {dayjs().year()} - Eleftherios Pegiadis
        </footer>
      </Fragment>
    )
  }
}

Layout.propTypes = {
  location: object,
  route: object
}

export default Layout
